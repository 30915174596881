// TODO: Fix ESLint violations and enable the rules below
/* eslint-disable eqeqeq */
/* eslint-disable block-scoped-var */
/* eslint-disable no-redeclare */

'use strict';

import debounce from 'lodash/debounce';

import { initBootstrapSelect } from '../../Shared/js/vendor/deferred';
import { initCartoDb } from '../../Shared/js/vendor/deferred/cartodbjs';
import { isInViewport } from './common/utils/ViewportHelper';

const instances = {};
let L;

function isVisible(el: HTMLElement) {
    return window.getComputedStyle(el).display !== 'none' && isInViewport(el);
}

const coverageMap = {
    loadLibraries: async (callback: () => void) => {
        if (L) {
            callback();
            return;
        }

        const mapbox = await import(/* webpackChunkName: "mapbox" */ 'mapbox-gl');

        if (!mapbox.supported()) {
            coverageMap.removeMap();
            return;
        }

        await initBootstrapSelect();
        WhistleOut.applySelectPicker(wo$('.container-widget-coverage-map .selectpicker'));

        await import(/* webpackChunkName: "leaflet" */ '../../Shared/js/vendor/deferred/leaflet');
        await initCartoDb();

        L = L || window.L;
        callback();
    },

    removeMap: () => {
        wo$('#map').remove();
        wo$('[data-goto-coverage-map]').remove();
    },

    init: (page: JQuery<HTMLElement>) => {
        const mapContainers = page.find('[data-coverage-maps]');
        if (!mapContainers.length) {
            return;
        }

        addEventListener('DOMContentLoaded', async () => {
            const mapbox = await import(/* webpackChunkName: "mapbox" */ 'mapbox-gl');

            if (!mapbox.supported()) {
                coverageMap.removeMap();
            }
        });

        wo$(document).ready(function () {
            let mapsNotInitialized = 0;
            mapContainers.each((i, el) => {
                const mapContainer = wo$(el);
                coverageMap.updateWidgetUrl(mapContainer);

                mapContainer
                    .find('input[data-embed-code]')
                    .off('click')
                    .on('click', (e: JQuery.ClickEvent<HTMLInputElement>) => {
                        WhistleOut.focusAndSelect(e.currentTarget, true);
                        WhistleOut.triggerPopoverNotification(e.currentTarget, 'Copied', 'bottom');
                    });

                mapContainer
                    .find('input[data-amp]')
                    .off()
                    .on('click', function () {
                        coverageMap.updateWidgetUrl(mapContainer);
                    });

                const placeholder = mapContainer.find('div[data-placeholder]');
                if (placeholder.length) {
                    placeholder.on('click', 'a', function () {
                        coverageMap.onMapActivateClick(mapContainer, placeholder);
                    });
                } else {
                    if (isVisible(el)) {
                        coverageMap.doInit(mapContainer);
                    } else {
                        mapsNotInitialized++;
                    }
                }
            });

            if (mapsNotInitialized > 0) {
                wo$(window).on('resize scroll', coverageMap.viewportChanged);
            }
        });
    },

    onMapActivateClick: (mapContainer: JQuery<HTMLElement>, placeholder: JQuery<HTMLElement>) => {
        placeholder.off('click');
        placeholder.closest('div.container-widget-coverage-map').find('style').remove();
        placeholder.remove();

        WhistleOut.trackEvent('CoverageMap', 'Activate');
        mapContainer.find('[data-map-content]>:first-child').removeClass('hidden');
        coverageMap.doInit(mapContainer);

        const attr = 'data-amp';
        const map = mapContainer.closest(`div[${attr}]`);
        if (map.length) {
            const isAmp = map[0].getAttribute(attr).toLowerCase() !== 'false';
            const message = Object.assign(
                {
                    height: document.body.scrollHeight,
                    type: 'embed-size'
                },
                isAmp ? { sentinel: 'amp' } : { sentinel: 'whistleout-widget', url: document.location.href }
            );

            window.parent.postMessage(message, '*');
        }
    },

    viewportChanged: debounce(() => {
        console.log('scrolled maps');
        const mapContainers = wo$('[data-coverage-maps]');
        let mapsNotInitilized = 0;
        mapContainers.each((i, el) => {
            const mapContainer = wo$(el);
            const placeholder = mapContainer.find('div[data-placeholder]');
            if (mapContainer.data('initialised') || placeholder.length > 0) return;
            if (isVisible(el)) {
                coverageMap.doInit(mapContainer);
            } else {
                mapsNotInitilized++;
            }
        });
        if (mapsNotInitilized === 0) {
            wo$(window).off('resize scroll', coverageMap.viewportChanged);
        }
    }, 100),

    doInit: (mapContainer: JQuery<HTMLElement>) => {
        if (mapContainer.data('initialised')) {
            return;
        }

        if (!L) {
            coverageMap.loadLibraries(() => {
                coverageMap.doInit(mapContainer);
            });
            return;
        }

        WhistleOut.applySelectPicker(wo$('.container-widget-coverage-map .selectpicker'));

        coverageMap.showMaps(mapContainer);
    },

    showMaps: (mapContainer: JQuery<HTMLElement>) => {
        const lat = mapContainer.data('lat');
        const lng = mapContainer.data('lng');
        const label = mapContainer.data('label');

        const instanceId: string = mapContainer.data('instance-id');
        console.log('initialized map ' + instanceId);
        instances[instanceId] = {};

        const nbnMapElement = mapContainer.find('#nbn-map-' + instanceId);
        if (nbnMapElement.length) {
            coverageMap.showNbnMap(nbnMapElement.get(0), lat, lng, label, instanceId);
        }

        const adslMapElement = mapContainer.find('#adsl-map-' + instanceId);
        if (adslMapElement.length) {
            coverageMap.showAdslMap(adslMapElement.get(0), lat, lng, label, instanceId);
        }

        const mobileMapElement = mapContainer.find('#mobile-map-' + instanceId);
        if (mobileMapElement.length) {
            coverageMap.showMobileMap(mobileMapElement.get(0), lat, lng, label, instanceId);
        }

        mapContainer.find<HTMLAnchorElement>('a[data-toggle="tab"]').on('click', e => {
            coverageMap.onTabClick(e, instanceId);
        });

        mapContainer.data('initialised', true);
    },

    onTabClick: (e: JQuery.ClickEvent<HTMLAnchorElement>, instanceId: string) => {
        const instance = instances[instanceId];
        const targetHref = wo$(e.currentTarget).attr('href');
        if (targetHref === '#nbn-coverage-tab-' + instanceId) {
            instance.nbnMap._onResize();
        } else if (targetHref === '#adsl-coverage-tab-' + instanceId) {
            instance.adslMap._onResize();
        } else if (targetHref === '#mobile-coverage-tab-' + instanceId) {
            instance.mobileMap._onResize();
        }
    },

    createMapBoxGl: () => {
        const styleId = WhistleOut.getSiteConfiguration().mapBoxStyle;
        const mapGl = coverageMap.createMapboxVectorLayer(styleId);
        return mapGl;
    },

    createMap: (element: HTMLElement, lat: number, lng: number, formattedAddress: string, mapGl) => {
        const map = L.map(element.id, {
            center: [lat, lng],
            zoom: wo$(element).data('zoom') || 14,

            attributionControl: false,
            scrollWheelZoom: false,
            zoomControl: true,
            fullscreenControl: true,
            tap: false
        });

        coverageMap.createAttribution().addTo(map);
        coverageMap.setMobileOptions(map);

        mapGl.addTo(map);

        if (coverageMap.shouldShowCurrentLocation(element) === 'true') {
            coverageMap.createAddressMarker(element, lat, lng).addTo(map).bindPopup(formattedAddress);
        }

        map.on('zoomend', function () {
            coverageMap.onZoomEnd(element, map);
        });

        return map;
    },

    createAttribution: () => {
        const attribution = L.control.attribution();
        attribution.addAttribution(
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        );
        return attribution;
    },

    createMapboxVectorLayer: (styleId: string) => {
        const mapBoxAccessToken = WhistleOut.getSiteConfiguration().mapBoxAccessToken;
        return L.mapboxGL({
            accessToken: mapBoxAccessToken,
            style: 'mapbox://styles/' + styleId
        });
    },

    shouldShowCurrentLocation: (element: HTMLElement) => {
        return wo$(element).data('show-current').toLowerCase();
    },

    createAddressMarker: (element: HTMLElement, lat: number, lng: number) => {
        const markerIconUrl = wo$(element).data('marker-url');
        const redIcon = new L.Icon({
            iconUrl: markerIconUrl,
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });

        return L.marker([lat, lng], {
            icon: redIcon
        });
    },

    onZoomEnd: (element: HTMLElement, map) => {
        coverageMap.updateEmbedUrls(element, 'zoom', map.getZoom());
    },

    showNbnMap: (element: HTMLElement, lat: number, lng: number, formattedAddress: string, instanceId: string) => {
        const instance = instances[instanceId];
        const mapBoxGl = coverageMap.createMapBoxGl();
        const map = coverageMap.createMap(element, lat, lng, formattedAddress, mapBoxGl);
        instance.nbnMap = map;

        const nbnCoLayer = L.layerGroup();
        nbnCoLayer.addLayer(
            L.tileLayer(
                'https://a.gusc.cartocdn.com/nbncoadmin/api/v1/map/nbncoadmin@b78fd7e7@5470a88b172e143668f43f195183a044:1729828414664/0,1,2,3,4,5,6,7,8,9/{z}/{x}/{y}.png'
            )
        );

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        nbnCoLayer.on('error', function () {}); // TODO: Log warning in Rollbar

        nbnCoLayer.addTo(map);
    },

    showAdslMap: (element: HTMLElement, lat: number, lng: number, formattedAddress: string, instanceId: string) => {
        const instance = instances[instanceId];
        const mapBoxGl = coverageMap.createMapBoxGl();
        const map = coverageMap.createMap(element, lat, lng, formattedAddress, mapBoxGl);
        instance.adslMap = map;

        const exchanges = wo$(element).data('exchanges');
        if (!exchanges) return;
        const exchangeIconUrl = wo$(element).data('exchange-url');

        const exchangeIcon = new L.Icon({
            iconUrl: exchangeIconUrl,
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [32, 37],
            iconAnchor: [12, 37],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });

        const showCurrentLocation = coverageMap.shouldShowCurrentLocation(element);
        for (let i = 0; i < exchanges.length; i++) {
            const exchange = exchanges[i];

            L.marker([exchange.lat, exchange.lng], {
                icon: exchangeIcon
            })
                .addTo(map)
                .bindPopup(exchange.name);
            if (showCurrentLocation === 'true') {
                const path = [
                    {
                        lat: lat,
                        lng: lng
                    },
                    {
                        lat: exchange.lat,
                        lng: exchange.lng
                    }
                ];
                L.polyline(path, {
                    color: '#FF0000',
                    opacity: 1.0,
                    weight: 2
                }).addTo(map);
            }
            if (exchange.boundary && exchange.boundary.length) {
                const polygon = new L.Polygon(exchange.boundary, {
                    color: '#8e50d8',
                    weight: 2,
                    opacity: 0.6,
                    fillOpacity: 0.5
                });
                map.addLayer(polygon);
            }
        }
    },

    showMobileMap: (element: HTMLElement, lat: number, lng: number, formattedAddress: string, instanceId: string) => {
        const instance = instances[instanceId];

        const mapBoxGl = coverageMap.createMapBoxGl();
        instance.mapBoxGl = mapBoxGl;

        const map = coverageMap.createMap(element, lat, lng, formattedAddress, mapBoxGl);
        instance.mobileMap = map;

        const mobileMapContainer = wo$(element).parent().parent();

        instance.providers = [];
        instance.mapboxLayers = [];

        coverageMap.reloadMobileLayers(element, instanceId);

        mobileMapContainer
            .find('input[data-mobile-sublayer]')
            .on('change', (e: JQuery.ChangeEvent<HTMLInputElement>) =>
                coverageMap.onSublayerChange(e, element, instanceId, mobileMapContainer)
            );

        mobileMapContainer
            .find<HTMLSelectElement>('select[data-mobile-provider]')
            .on('change', (e: JQuery.ChangeEvent<HTMLSelectElement>) =>
                coverageMap.onProviderChange(e, element, instanceId, mobileMapContainer)
            );
    },

    onSublayerChange: (
        e: JQuery.ChangeEvent<HTMLInputElement>,
        element: HTMLElement,
        instanceId: string,
        mobileMapContainer: JQuery<HTMLElement>
    ) => {
        // The HTML element attributes are not updated by default,
        // do this here explicitly
        if (e.currentTarget.checked) {
            e.currentTarget.setAttribute('checked', '');
        } else {
            e.currentTarget.removeAttribute('checked');
        }

        coverageMap.reloadMobileLayers(element, instanceId);

        const networks = [];
        if (mobileMapContainer.find('input[data-mobile-sublayer]:visible:not(:checked)').length > 0) {
            const checkedNetworkTypes = mobileMapContainer.find('input[data-mobile-sublayer]:visible:checked');
            checkedNetworkTypes.each((i, network) => {
                networks.push(wo$(network).data('mobile-sublayer'));
            });
            coverageMap.updateEmbedUrls(mobileMapContainer, 'defaultMobileNetworkTypes', networks.join());
        } else {
            coverageMap.updateEmbedUrls(mobileMapContainer, 'defaultMobileNetworkTypes', null);
        }
    },

    onProviderChange: (
        e: JQuery.ChangeEvent<HTMLSelectElement>,
        element: HTMLElement,
        instanceId: string,
        mobileMapContainer: JQuery<HTMLElement>
    ) => {
        // The HTML element attributes are not updated by default,
        // do this here explicitly
        [...e.currentTarget.options].forEach((p, i) => {
            if (e.currentTarget.selectedIndex === i) {
                p.setAttribute('selected', '');
            } else {
                p.removeAttribute('selected');
            }
        });

        const checkboxes = mobileMapContainer.find('input[data-mobile-sublayer]');

        // TODO: try using prop() instead of attr() or vanilla JS
        // HACK: .prop() doesn't work for some reason
        checkboxes.attr('checked', '');

        checkboxes.parent().removeClass('hidden');

        const selected = wo$(e.currentTarget).find(':selected');
        coverageMap.updateEmbedUrls(mobileMapContainer, 'defaultMobileNetwork', selected.val() as string);
        coverageMap.updateEmbedUrls(mobileMapContainer, 'defaultMobileNetworkTypes', null);

        if (selected.data('3g-hidden') == true) {
            mobileMapContainer
                .find('#threeg-checkbox-' + instanceId)
                .parent()
                .addClass('hidden');
        }
        if (selected.data('5g-hidden') == true) {
            mobileMapContainer
                .find('#fiveg-checkbox-' + instanceId)
                .parent()
                .addClass('hidden');
        }

        coverageMap.reloadMobileLayers(element, instanceId);
    },

    reloadMobileLayers: function (container: HTMLElement, instanceId: string) {
        const instance = instances[instanceId];

        // Remove existing items
        instance.providers.splice(0, instance.providers.length);

        const mapBoxGl = instance.mapBoxGl.getMapboxMap();
        if (mapBoxGl.isStyleLoaded()) {
            coverageMap.loadMapBoxLayers(container, mapBoxGl, instance);
        } else {
            mapBoxGl.on('style.load', () => {
                coverageMap.loadMapBoxLayers(container, mapBoxGl, instance);
            });
        }
    },

    loadMapBoxLayers: (container: HTMLElement, mapBoxGl, instance) => {
        wo$.each(instance.mapboxLayers, function (index, value) {
            if (mapBoxGl.getLayer(value)) mapBoxGl.removeLayer(value);
            if (mapBoxGl.getSource(value)) mapBoxGl.removeSource(value);
        });
        instance.mapboxLayers = [];
        wo$(wo$(container).parent().parent().find('input[data-mobile-sublayer]').get().reverse()).each(
            (i, el: HTMLInputElement) => {
                const option = wo$(container).parent().parent().find('select[data-mobile-provider] option:selected');

                if (el.checked) {
                    let fillColor;
                    let layer;
                    if (wo$(el).data('mobile-sublayer') === '3g') {
                        fillColor = '#af8cdb';
                        layer = option.data('3g-mapbox');
                    } else if (wo$(el).data('mobile-sublayer') === '4g') {
                        fillColor = '#8f53d5';
                        layer = option.data('4g-mapbox');
                    } else if (wo$(el).data('mobile-sublayer') === '5g') {
                        fillColor = '#710FE3';
                        layer = option.data('5g-mapbox');
                    }
                    if (layer && layer.length) {
                        const layers = layer.split(',');
                        wo$.each(layers, function (index, v) {
                            instance.mapboxLayers.push(v);
                            const values = v.split('|');
                            if (values.length === 2) {
                                const source = values[0];
                                const layer = values[1];
                                mapBoxGl.addSource(v, {
                                    type: 'vector',
                                    url: 'mapbox://' + source
                                });
                                mapBoxGl.addLayer({
                                    id: v,
                                    type: 'fill',
                                    source: v,
                                    'source-layer': layer,
                                    paint: {
                                        'fill-color': fillColor,
                                        'fill-opacity': 0.5
                                    }
                                });
                            }
                        });
                    }
                }
            }
        );
    },

    setMobileOptions: map => {
        if (WhistleOut.isMobileDevice()) {
            map.dragging.disable();
            const box = L.control
                .messagebox({
                    timeout: 9999999
                })
                .addTo(map);
            box.show('Use two fingers to scroll the map');
        }
    },

    updateEmbedUrls: (element: JQuery<HTMLElement> | HTMLElement, queryString: string, value: string) => {
        const map = wo$(element).closest('[data-coverage-maps]');
        const ampUrl = map.data('embed-ampurl');
        const url = map.data('embed-url');

        if (!ampUrl || !url) {
            return;
        }

        map.data('embed-ampurl', WhistleOut.updateQueryStringParameter(ampUrl, queryString, value));
        map.data('embed-url', WhistleOut.updateQueryStringParameter(url, queryString, value));
        coverageMap.updateWidgetUrl(map);
    },

    updateWidgetUrl: (element: JQuery<HTMLElement> | HTMLElement) => {
        let url, format;
        const isAmp = wo$(element).find('input[data-amp]').is(':checked');
        if (isAmp) {
            url = wo$(element).data('embed-ampurl');
            url = url.replace(/&/g, '&amp;');
            format = wo$(element).data('embed-ampformat');
        } else {
            url = wo$(element).data('embed-url');
            format = wo$(element).data('embed-format');
        }

        wo$(element).find('input[data-embed-code]').val(format.replace('{url}', url));
    }
};

export type CoverageMapType = typeof coverageMap;

function main() {
    WhistleOut.CoverageMap = coverageMap;
    WhistleOut.initWidget = () => coverageMap.init(wo$('body'));
    WhistleOut.initWidget();
}

WhistleOut.initModule(import.meta.url, main);
